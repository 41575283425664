<template>
  <v-sheet flat class="pa-10">
    <v-select solo style="width: 200px;"
      v-model="season_id"
      :items="unarchived_seasons"
      item-text="season_name"
      item-value="id"
      label="Kausi"
      :loading="loading_seasons"
      @change="changeSeason($event)"
    ></v-select>
    <v-sheet
      v-if="loading_games"
      class="d-flex justify-center my-4"
    >
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-sheet>
    <v-row>
      <v-col
        cols="12" lg="4" md="6" sm="12"
        v-for="game in games"
        :key="game.id + 'game'"
      >
        <user-game-card
          :season="season"
          :game="game"
        ></user-game-card>
      </v-col>
    </v-row>
    <v-card-title v-if="games.length == 0 && !loading_games">
      Ei vielä pelejä
    </v-card-title>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserGameCard from '../components/tiimi_admin/league/game/UserGameCard.vue'

export default {
  components: { UserGameCard },
  data: () => ({
    season_id: null
  }),
  created() {
    this.initSeasons(this.currentTeam.league_id)

    let q = this.$route.query.season_id
    if(q) {
      this.season_id = parseInt(q)
      this.initGames({season_id: this.season_id})
        .then(e => {
          this.finalizeInit(e.season_id)
        })
    } else {
      this.initGames({})
        .then(e => {
          this.finalizeInit(e.season_id)
        })
    }
  },
  methods: {
    ...mapActions('admin', [
      'initGames',
      'initSeasons',
      'getSeasonStates'
    ]),
    finalizeInit(season_id) {
      this.season_id = season_id
      this.getSeasonStates({ id: season_id })
    },
    changeSeason(season_id) {
      this.$router.replace({ query: { season_id }})
      this.initGames({ season_id })
    }
  },
  computed: {
    ...mapGetters('admin', [
      'games',
      'seasons',
      'loading_seasons',
      'loading_games',
      'season_by_id',
      'unarchived_seasons'
    ]),
    ...mapGetters('user', [
      'currentTeam'
    ]),
    season() {
      return this.season_by_id(this.season_id) || {}
    }
  }
}
</script>